<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  name: 'WyndhamLogo',
  props: {
    label: {
      type: String,
    },
  },
  computed: {
    viewBox() {
      if (this.logoType > 1) {
        if (this.logoType === 2)
          return '100 100 997.28 338.73'

        return '0 0 179.17 105.45'
      }
      else {
        return '0 0 1197.28 607.39'
      }
    },

    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    logoType() {
      if (this.currentPage && this.currentPage.slug) {
        if (this.currentPage.slug.includes('/cancun'))
          return 1
        else if (this.currentPage.slug.includes('/playa-del-carmen'))
          return 3
      }
      return 2
    },
  },
}
</script>

<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :viewBox="viewBox"
    style="enable-background: new 0 0 179.17 105.45"
    xml:space="preserve"
    :aria-labelledby="label"
  >
    <g v-if="logoType === 1" class="relative">
      <g style="display: block" class="relative">
        <path
          style="fill: currentcolor"
          d="M543.33,201.5H531.72L501.93,271h13.58l6.76-16.32h30.22L559.1,271h14Zm4.29,41.22H527.08l10.25-24.54Z"
        />
        <path
          style="fill: currentcolor"
          d="M388.61,202H363.45V271h25.16c21.91,0,35.6-15.27,35.6-34.57,0-16.91-11.69-34.38-35.6-34.38m0,56.73H376.38V214.19h12.23c13.47,0,22.17,9.41,22.17,22.39,0,12.62-8.55,22.15-22.17,22.15"
        />
        <polygon
          style="fill: currentcolor"
          points="248.49 229.23 232.29 202.01 217.05 202.01 241.86 242.52 241.86 270.97 254.88 270.97 254.88 242.22 279.8 202.01 264.94 202.01 248.49 229.23"
        />
        <polygon
          style="fill: currentcolor"
          points="192.25 271.43 212.02 202.01 198.46 202.01 186.03 249.21 171.74 201.81 159.25 201.81 144.73 249.21 132.34 202.01 118.44 202.01 138.44 271.43 149.92 271.43 165.06 222.13 180.47 271.43 192.25 271.43"
        />
        <polygon
          style="fill: currentcolor"
          points="333.28 247.33 299.09 202.01 287.37 202.01 287.37 235.68 287.37 270.96 300.1 270.96 300.1 224.24 335.32 270.96 345.97 270.96 345.97 236.62 345.97 202.01 333.28 202.01 333.28 247.33"
        />
        <polygon
          style="fill: currentcolor"
          points="492.75 270.96 492.75 235.68 492.75 202.01 479.87 202.01 479.87 230.08 449.88 230.08 449.88 202.01 436.95 202.01 436.95 234.73 436.95 270.96 449.88 270.96 449.88 242.49 479.87 242.49 479.87 270.96 492.75 270.96"
        />
        <polygon
          style="fill: currentcolor"
          points="613.9 233.33 594.27 202.01 580.92 202.01 580.92 233.82 580.92 270.96 593.65 270.96 593.65 224.33 613.16 254.35 614.26 254.35 633.95 224.12 633.95 270.96 646.87 270.96 646.87 232.86 646.87 202.01 633.49 202.01 613.9 233.33"
        />
        <path
          style="fill: currentcolor"
          d="M493.76,484.48q-4-3.74-4-10.64,0-7.12,4-10.88t11.68-3.75a25,25,0,0,1,4.52.37,26.18,26.18,0,0,1,4.09,1.11v7.17a19.62,19.62,0,0,0-8-1.66q-4,0-5.92,1.83t-1.9,5.81q0,3.84,2,5.61c1.32,1.18,3.3,1.77,5.92,1.77a20.08,20.08,0,0,0,8-1.61v7.21a26.49,26.49,0,0,1-8.65,1.39Q497.78,488.21,493.76,484.48Z"
        />
        <path
          style="fill: currentcolor"
          d="M534.52,459.64h8.69L554,487.78h-8.56l-2.36-6h-8.47l-2.32,6h-8.56ZM542,475.89l-3.15-8.25-3.14,8.25Z"
        />
        <path
          style="fill: currentcolor"
          d="M564.53,459.64h7l10.62,14.9v-14.9h8.21v28.14h-7l-10.58-14.86v14.86h-8.21Z"
        />
        <path
          style="fill: currentcolor"
          d="M604.9,484.48q-4-3.74-4-10.64,0-7.12,4-10.88t11.68-3.75a25.17,25.17,0,0,1,4.53.37,26.37,26.37,0,0,1,4.08,1.11v7.17a19.58,19.58,0,0,0-8-1.66q-4,0-5.92,1.83c-1.26,1.22-1.9,3.16-1.9,5.81s.67,4.43,2,5.61,3.3,1.77,5.92,1.77a20.08,20.08,0,0,0,8-1.61v7.21a26.45,26.45,0,0,1-8.65,1.39Q608.91,488.21,604.9,484.48Z"
        />
        <path
          style="fill: currentcolor"
          d="M639.16,485.26a10.08,10.08,0,0,1-3.64-8.32v-17.3h8.21v16.43c0,3.44,1.7,5.15,5.11,5.15s5.07-1.71,5.07-5.15V459.64h8.21v17.3a10.9,10.9,0,0,1-1.66,6.12,10.33,10.33,0,0,1-4.67,3.84,17.2,17.2,0,0,1-6.95,1.31Q642.81,488.21,639.16,485.26Z"
        />
        <path
          style="fill: currentcolor"
          d="M673.3,459.64h7l10.62,14.9v-14.9h8.21v28.14h-7l-10.57-14.86v14.86H673.3Z"
        />
        <path
          style="fill: currentcolor"
          d="M1003,302.51c-50.76,27.67-90.63,32.71-115.3,14.57-19.21-14.14-25.6-40.21-23.92-61.84a294.41,294.41,0,0,0,22.32-36.41c10.19-19.55,18.71-40.31,23.36-57,5.71-20.39,5.49-32.94-.66-38.34a9.06,9.06,0,0,0-9.25-2c-5.93,2.07-15.42,11.08-29.31,65.22-7.45,29-12.63,57.59-12.67,57.81-.51,2.6-.91,5.25-1.19,7.93-11.58,15.2-21.16,22.18-26.44,21-5.29-1.63-8.13-7.89-4.86-32.82l.23-1.76,0-.36c27-32.64,39.92-75,37.62-93.51-1-7.57-4.26-10.36-6.88-11.36a10.13,10.13,0,0,0-9.08.85c-11.5,6.85-20.69,38.89-28.9,100.76a128.14,128.14,0,0,1-19.44,18.68c-.67-8.31-1.1-15.86-1.37-22.16a500.6,500.6,0,0,1,.62-59.94c5.86-15.69,4.28-18,3.38-19.28a4.4,4.4,0,0,0-4.33-1.85l-.15,0a4.79,4.79,0,0,0-3.31,2.67c-1.27,2.39-2.31,8.4-3.06,16.75-6.33,16.54-20.94,48.52-51,103.62q-22.63-.88-47.15-13.19a3.8,3.8,0,1,0-3.42,6.79c15.87,8,31.48,12.53,46.6,13.65q-9.48,17.24-20.92,37.37a3.8,3.8,0,0,0,6.61,3.76c8.5-14.94,16.07-28.53,22.83-40.86a95.43,95.43,0,0,0,48.29-13.83c2.78,26.61,8.08,58,18.16,82.15a3.8,3.8,0,0,0,7-2.92c-10.24-24.58-15.41-57.05-18-83.89a127.26,127.26,0,0,0,17.11-14.57c-1.61,17.76.37,29.3,11.3,32.54l.18.05c8.68,2.13,18.61-5.24,27.88-15.77a92.26,92.26,0,0,0,3.16,21.29c4.3,15.68,12.63,28.44,24.11,36.88,11.78,8.66,25.59,12.13,40.17,12.13,28,0,58.73-12.77,83.27-26.15a3.8,3.8,0,0,0-3.64-6.68ZM877.61,188.58c13.43-52.36,22.1-59.09,24.43-59.9a1.31,1.31,0,0,1,.44-.09,2,2,0,0,1,1.29.66c2.68,2.36,3.66,11.63-1.64,30.58-4.52,16.16-12.83,36.38-22.78,55.49-4.34,8.31-8.6,15.72-12.72,22.25C868.7,227,872.65,207.89,877.61,188.58Zm-43.4-4.45c7.1-33.09,13.5-41.22,16.69-43.12a2.9,2.9,0,0,1,1.5-.48,2.66,2.66,0,0,1,1,.2c.55.21,1.59,1.54,2,5.2,1.86,14.92-7.91,48.32-28,77.17C829.06,211.8,831.31,197.64,834.21,184.13Zm-86.08,89.54c19.75-36.36,31.84-61,39-76.55.76-1.62,1.47-3.19,2.15-4.7-.29,12.17-.18,26,.42,39.77.32,7.47.86,16.66,1.77,26.82Q770.92,272.32,748.13,273.67Z"
        />
        <path
          style="fill: currentcolor"
          d="M1077.42,256.57a3.8,3.8,0,0,0-5.36-.4c-24.42,21-33.2,17.93-35.31,16.48-3.8-2.62-5.65-11.36-1.66-28.16.37-1.13.74-2.3,1.1-3.52a3.64,3.64,0,0,0,.14-1.3c1.07-3.81,2.4-8,4-12.55a3.8,3.8,0,0,0-7.16-2.57l-.51,1.43c-8.16-3.51-15.7-3.57-22.42-.16s-11.47,9.86-14.65,16.06a4.47,4.47,0,0,0-.43.74,124.51,124.51,0,0,1-10.15,18.17c-3.93,5.69-11.38,14.87-17.08,12.38-4.57-2-1.51-14.08.95-23.8,1-3.78,1.94-7.66,2.63-11.35,1-4.72,1.37-8.52.2-10.81-2.59-5.07-5.27-5-12.17-6.53l-2.1-.2-.49-2a22.35,22.35,0,0,0-1.16-3.94c-1.9-4.46-4.24-5-6.08-4.89a5.27,5.27,0,0,0-4,1.91,7.39,7.39,0,0,0-2,5,9,9,0,0,0,2,6.45,207.47,207.47,0,0,1-9.56,25.94c-7.91,17.28-16.05,26-22.94,24.57a6,6,0,0,1-4-3.58c-5.5-11.72,3.34-42.73,13.69-70.68,2.25-.16,5-.36,5-.43,22-1.68,65.29-5.57,74.89-6.73a4.09,4.09,0,0,0,3.13-2.37,4.2,4.2,0,0,0,0-3.6c-.52-1.13-1.58-1.65-3.16-1.54-31.64,3.79-41.46,4.81-73.31,6.81,0,0-1.49,0-3.59,0,5.52-14.27,11.13-27.1,15-35.27a3.8,3.8,0,0,0-6.89-3.23c-.1.2-8.17,17.48-16.36,38.89a21.7,21.7,0,0,0-4.88.81,4,4,0,0,0-2.38,2.57,4.15,4.15,0,0,0,.28,3.32,3,3,0,0,0,3,1.47l.92-.07-.93,2.56c-12.94,36.2-16.64,59.32-11.3,70.68a13.56,13.56,0,0,0,9,7.73l.14,0a15.08,15.08,0,0,0,3.23.36c19.43,0,33-38.53,37.74-54.27l5.23,1,3.4.44c4.14.32,3.06,3.91,2.66,6.82-.45,3.24-1.42,8.35-4,19.09h0c-2.64,11.45-3.74,22.07,5,25.9a12.43,12.43,0,0,0,5,1.08c6.83,0,13.34-5.67,18.71-12.5.21,5.19,2.07,8.86,5.58,11a11.74,11.74,0,0,0,6.18,1.59c7.52,0,16.71-5.45,22.23-12.27a33.14,33.14,0,0,0,2.84-3.74c.72,6.35,3,10.94,6.94,13.63a14.53,14.53,0,0,0,8.44,2.49c9,0,21.1-6.51,36.14-19.46A3.81,3.81,0,0,0,1077.42,256.57Zm-60.37,7.31-.24.27c-5.8,7.23-15.25,11.08-18.62,9-2.86-1.73-3.44-10.07,4.34-27.89a4.12,4.12,0,0,0,.21-.62c2.56-4.85,6.15-9.63,11-12.09s10.24-2.22,16.56.64q-1.5,4.85-2.55,9.23C1022.69,257.79,1017.1,263.83,1017.05,263.88Z"
        />
        <path
          style="fill: currentcolor"
          d="M278.63,391.6h5.49l12.12,28.3H290l-2.64-6.41H275.18l-2.63,6.41h-6.08Zm6.62,16.64-3.95-9.53-4,9.53Z"
        />
        <path
          style="fill: currentcolor"
          d="M307.47,391.8h5.92v22.69H327.6v5.41H307.47Z"
        />
        <path
          style="fill: currentcolor"
          d="M339.09,391.8H345v22.69h14.21v5.41H339.09Z"
        />
        <path
          style="fill: currentcolor"
          d="M369.33,404.65h12.31v5.68H369.33Z"
        />
        <path style="fill: currentcolor" d="M394.14,391.8h6v28.1h-6Z" />
        <path
          style="fill: currentcolor"
          d="M414,391.8h5.46l13.59,17.71V391.8h5.88v28.1h-5.07l-14-18.3v18.3H414Z"
        />
        <path
          style="fill: currentcolor"
          d="M451.28,405.92v-.07A14.29,14.29,0,0,1,466,391.33c5.31,0,8.48,1.74,11.12,4.33l-3.8,4.36c-2.2-2-4.45-3.24-7.35-3.24-4.88,0-8.4,4.05-8.4,9v.08c0,4.94,3.44,9.07,8.4,9.07,3.29,0,5.3-1.31,7.55-3.36l3.79,3.82a14.52,14.52,0,0,1-11.54,5A14.19,14.19,0,0,1,451.28,405.92Z"
        />
        <path
          style="fill: currentcolor"
          d="M489,391.8H495v22.69h14.21v5.41H489Z"
        />
        <path
          style="fill: currentcolor"
          d="M520.19,407.89V391.8h6v15.9c0,4.71,2.36,7.18,6.27,7.18s6.31-2.35,6.31-6.95V391.8h5.93v15.86c0,8.49-4.76,12.66-12.31,12.66S520.19,416.12,520.19,407.89Z"
        />
        <path
          style="fill: currentcolor"
          d="M555.88,415.81l3.53-4.21a13,13,0,0,0,8.51,3.47c2.64,0,4.26-1.12,4.26-2.85v-.08c0-1.66-1-2.51-5.8-3.71-5.77-1.42-9.37-3.05-9.37-8.57v-.07c0-5,4.1-8.38,9.83-8.38A16.23,16.23,0,0,1,577.25,395l-3.13,4.48a12.94,12.94,0,0,0-7.4-2.82c-2.47,0-3.79,1.2-3.79,2.63v.07c0,1.93,1.24,2.59,6.19,3.86,5.77,1.47,9,3.56,9,8.38v.08c0,5.52-4.25,8.65-10.29,8.65A17.8,17.8,0,0,1,555.88,415.81Z"
        />
        <path style="fill: currentcolor" d="M590.61,391.8h6v28.1h-6Z" />
        <path
          style="fill: currentcolor"
          d="M608,391.8h6.58L622.2,412l7.59-20.15h6.42l-11.46,28.29h-5.26Z"
        />
        <path
          style="fill: currentcolor"
          d="M647.4,391.8h21.18v5.28h-15.3v6h13.48v5.29H653.28v6.21h15.49v5.29H647.4Z"
        />
        <path
          style="fill: currentcolor"
          d="M699.93,391.8h12.82c3.56,0,6.35,1,8.2,2.85a8.72,8.72,0,0,1,2.37,6.33v.08a8.67,8.67,0,0,1-6.16,8.69l7.05,10.15h-7L711,410.75h-5.14v9.15h-5.93Zm12.43,13.7c3.1,0,5-1.66,5-4.13v-.08c0-2.7-1.94-4.13-5.08-4.13h-6.38v8.34Z"
        />
        <path
          style="fill: currentcolor"
          d="M735.78,391.8H757v5.28H741.67v6h13.47v5.29H741.67v6.21h15.48v5.29H735.78Z"
        />
        <path
          style="fill: currentcolor"
          d="M767.56,415.81l3.53-4.21a13,13,0,0,0,8.51,3.47c2.64,0,4.26-1.12,4.26-2.85v-.08c0-1.66-1-2.51-5.8-3.71-5.77-1.42-9.37-3.05-9.37-8.57v-.07c0-5,4.1-8.38,9.83-8.38A16.23,16.23,0,0,1,788.93,395l-3.13,4.48a12.94,12.94,0,0,0-7.4-2.82c-2.47,0-3.79,1.2-3.79,2.63v.07c0,1.93,1.24,2.59,6.19,3.86,5.77,1.47,9,3.56,9,8.38v.08c0,5.52-4.25,8.65-10.29,8.65A17.8,17.8,0,0,1,767.56,415.81Z"
        />
        <path
          style="fill: currentcolor"
          d="M800.74,405.92v-.07c0-8,6.31-14.52,15-14.52s14.91,6.45,14.91,14.44v.08c0,7.95-6.31,14.51-15,14.51S800.74,413.92,800.74,405.92Zm23.65,0v-.07a8.78,8.78,0,0,0-8.74-9.07,8.65,8.65,0,0,0-8.68,9v.08a8.78,8.78,0,0,0,8.75,9.07C820.83,414.92,824.39,410.87,824.39,405.92Z"
        />
        <path
          style="fill: currentcolor"
          d="M843,391.8h12.81c3.56,0,6.35,1,8.21,2.85a8.72,8.72,0,0,1,2.36,6.33v.08a8.67,8.67,0,0,1-6.16,8.69l7,10.15h-7l-6.23-9.15H848.9v9.15H843Zm12.42,13.7c3.1,0,5-1.66,5-4.13v-.08c0-2.7-1.94-4.13-5.07-4.13H848.9v8.34Z"
        />
        <path
          style="fill: currentcolor"
          d="M885.44,397.28h-8.67V391.8H900v5.48h-8.63V419.9h-6Z"
        />
        <path
          style="fill: currentcolor"
          d="M909.68,415.81l3.52-4.21a13,13,0,0,0,8.52,3.47c2.63,0,4.26-1.12,4.26-2.85v-.08c0-1.66-1-2.51-5.81-3.71-5.77-1.42-9.37-3.05-9.37-8.57v-.07c0-5,4.1-8.38,9.83-8.38A16.26,16.26,0,0,1,931.05,395l-3.14,4.48a12.91,12.91,0,0,0-7.39-2.82c-2.48,0-3.8,1.2-3.8,2.63v.07c0,1.93,1.24,2.59,6.2,3.86,5.77,1.47,9,3.56,9,8.38v.08c0,5.52-4.26,8.65-10.3,8.65A17.79,17.79,0,0,1,909.68,415.81Z"
        />
      </g>
    </g>
    <g v-else-if="logoType === 2">
      <g style="display: inline">
        <path
          style="fill: currentcolor"
          d="M543.33,201.5H531.72L501.93,271h13.58l6.76-16.32h30.22L559.1,271h14Zm4.29,41.22H527.08l10.25-24.54Z"
        />
        <path
          style="fill: currentcolor"
          d="M388.61,202H363.45V271h25.16c21.91,0,35.6-15.27,35.6-34.57,0-16.91-11.69-34.38-35.6-34.38m0,56.73H376.38V214.19h12.23c13.47,0,22.17,9.41,22.17,22.39,0,12.62-8.55,22.15-22.17,22.15"
        />
        <polygon
          style="fill: currentcolor"
          points="248.49 229.23 232.29 202.01 217.05 202.01 241.86 242.52 241.86 270.97 254.88 270.97 254.88 242.22 279.8 202.01 264.94 202.01 248.49 229.23"
        />
        <polygon
          style="fill: currentcolor"
          points="192.25 271.43 212.02 202.01 198.46 202.01 186.03 249.21 171.74 201.81 159.25 201.81 144.73 249.21 132.34 202.01 118.44 202.01 138.44 271.43 149.92 271.43 165.06 222.13 180.47 271.43 192.25 271.43"
        />
        <polygon
          style="fill: currentcolor"
          points="333.28 247.33 299.09 202.01 287.37 202.01 287.37 235.68 287.37 270.96 300.1 270.96 300.1 224.24 335.32 270.96 345.97 270.96 345.97 236.62 345.97 202.01 333.28 202.01 333.28 247.33"
        />
        <polygon
          style="fill: currentcolor"
          points="492.75 270.96 492.75 235.68 492.75 202.01 479.87 202.01 479.87 230.08 449.88 230.08 449.88 202.01 436.95 202.01 436.95 234.73 436.95 270.96 449.88 270.96 449.88 242.49 479.87 242.49 479.87 270.96 492.75 270.96"
        />
        <polygon
          style="fill: currentcolor"
          points="613.9 233.33 594.27 202.01 580.92 202.01 580.92 233.82 580.92 270.96 593.65 270.96 593.65 224.33 613.16 254.35 614.26 254.35 633.95 224.12 633.95 270.96 646.87 270.96 646.87 232.86 646.87 202.01 633.49 202.01 613.9 233.33"
        />
        <path
          style="fill: currentcolor"
          d="M1003,302.51c-50.76,27.67-90.63,32.71-115.3,14.57-19.21-14.14-25.6-40.21-23.92-61.84a294.41,294.41,0,0,0,22.32-36.41c10.19-19.55,18.71-40.31,23.36-57,5.71-20.39,5.49-32.94-.66-38.34a9.06,9.06,0,0,0-9.25-2c-5.93,2.07-15.42,11.08-29.31,65.22-7.45,29-12.63,57.59-12.67,57.81-.51,2.6-.91,5.25-1.19,7.93-11.58,15.2-21.16,22.18-26.44,21-5.29-1.63-8.13-7.89-4.86-32.82l.23-1.76,0-.36c27-32.64,39.92-75,37.62-93.51-1-7.57-4.26-10.36-6.88-11.36a10.13,10.13,0,0,0-9.08.85c-11.5,6.85-20.69,38.89-28.9,100.76a128.14,128.14,0,0,1-19.44,18.68c-.67-8.31-1.1-15.86-1.37-22.16a500.6,500.6,0,0,1,.62-59.94c5.86-15.69,4.28-18,3.38-19.28a4.4,4.4,0,0,0-4.33-1.85l-.15,0a4.79,4.79,0,0,0-3.31,2.67c-1.27,2.39-2.31,8.4-3.06,16.75-6.33,16.54-20.94,48.52-51,103.62q-22.63-.88-47.15-13.19a3.8,3.8,0,1,0-3.42,6.79c15.87,8,31.48,12.53,46.6,13.65q-9.48,17.24-20.92,37.37a3.8,3.8,0,0,0,6.61,3.76c8.5-14.94,16.07-28.53,22.83-40.86a95.43,95.43,0,0,0,48.29-13.83c2.78,26.61,8.08,58,18.16,82.15a3.8,3.8,0,0,0,7-2.92c-10.24-24.58-15.41-57.05-18-83.89a127.26,127.26,0,0,0,17.11-14.57c-1.61,17.76.37,29.3,11.3,32.54l.18.05c8.68,2.13,18.61-5.24,27.88-15.77a92.26,92.26,0,0,0,3.16,21.29c4.3,15.68,12.63,28.44,24.11,36.88,11.78,8.66,25.59,12.13,40.17,12.13,28,0,58.73-12.77,83.27-26.15a3.8,3.8,0,0,0-3.64-6.68ZM877.61,188.58c13.43-52.36,22.1-59.09,24.43-59.9a1.31,1.31,0,0,1,.44-.09,2,2,0,0,1,1.29.66c2.68,2.36,3.66,11.63-1.64,30.58-4.52,16.16-12.83,36.38-22.78,55.49-4.34,8.31-8.6,15.72-12.72,22.25C868.7,227,872.65,207.89,877.61,188.58Zm-43.4-4.45c7.1-33.09,13.5-41.22,16.69-43.12a2.9,2.9,0,0,1,1.5-.48,2.66,2.66,0,0,1,1,.2c.55.21,1.59,1.54,2,5.2,1.86,14.92-7.91,48.32-28,77.17C829.06,211.8,831.31,197.64,834.21,184.13Zm-86.08,89.54c19.75-36.36,31.84-61,39-76.55.76-1.62,1.47-3.19,2.15-4.7-.29,12.17-.18,26,.42,39.77.32,7.47.86,16.66,1.77,26.82Q770.92,272.32,748.13,273.67Z"
        />
        <path
          style="fill: currentcolor"
          d="M1077.42,256.57a3.8,3.8,0,0,0-5.36-.4c-24.42,21-33.2,17.93-35.31,16.48-3.8-2.62-5.65-11.36-1.66-28.16.37-1.13.74-2.3,1.1-3.52a3.64,3.64,0,0,0,.14-1.3c1.07-3.81,2.4-8,4-12.55a3.8,3.8,0,0,0-7.16-2.57l-.51,1.43c-8.16-3.51-15.7-3.57-22.42-.16s-11.47,9.86-14.65,16.06a4.47,4.47,0,0,0-.43.74,124.51,124.51,0,0,1-10.15,18.17c-3.93,5.69-11.38,14.87-17.08,12.38-4.57-2-1.51-14.08.95-23.8,1-3.78,1.94-7.66,2.63-11.35,1-4.72,1.37-8.52.2-10.81-2.59-5.07-5.27-5-12.17-6.53l-2.1-.2-.49-2a22.35,22.35,0,0,0-1.16-3.94c-1.9-4.46-4.24-5-6.08-4.89a5.27,5.27,0,0,0-4,1.91,7.39,7.39,0,0,0-2,5,9,9,0,0,0,2,6.45,207.47,207.47,0,0,1-9.56,25.94c-7.91,17.28-16.05,26-22.94,24.57a6,6,0,0,1-4-3.58c-5.5-11.72,3.34-42.73,13.69-70.68,2.25-.16,5-.36,5-.43,22-1.68,65.29-5.57,74.89-6.73a4.09,4.09,0,0,0,3.13-2.37,4.2,4.2,0,0,0,0-3.6c-.52-1.13-1.58-1.65-3.16-1.54-31.64,3.79-41.46,4.81-73.31,6.81,0,0-1.49,0-3.59,0,5.52-14.27,11.13-27.1,15-35.27a3.8,3.8,0,0,0-6.89-3.23c-.1.2-8.17,17.48-16.36,38.89a21.7,21.7,0,0,0-4.88.81,4,4,0,0,0-2.38,2.57,4.15,4.15,0,0,0,.28,3.32,3,3,0,0,0,3,1.47l.92-.07-.93,2.56c-12.94,36.2-16.64,59.32-11.3,70.68a13.56,13.56,0,0,0,9,7.73l.14,0a15.08,15.08,0,0,0,3.23.36c19.43,0,33-38.53,37.74-54.27l5.23,1,3.4.44c4.14.32,3.06,3.91,2.66,6.82-.45,3.24-1.42,8.35-4,19.09h0c-2.64,11.45-3.74,22.07,5,25.9a12.43,12.43,0,0,0,5,1.08c6.83,0,13.34-5.67,18.71-12.5.21,5.19,2.07,8.86,5.58,11a11.74,11.74,0,0,0,6.18,1.59c7.52,0,16.71-5.45,22.23-12.27a33.14,33.14,0,0,0,2.84-3.74c.72,6.35,3,10.94,6.94,13.63a14.53,14.53,0,0,0,8.44,2.49c9,0,21.1-6.51,36.14-19.46A3.81,3.81,0,0,0,1077.42,256.57Zm-60.37,7.31-.24.27c-5.8,7.23-15.25,11.08-18.62,9-2.86-1.73-3.44-10.07,4.34-27.89a4.12,4.12,0,0,0,.21-.62c2.56-4.85,6.15-9.63,11-12.09s10.24-2.22,16.56.64q-1.5,4.85-2.55,9.23C1022.69,257.79,1017.1,263.83,1017.05,263.88Z"
        />
        <path
          style="fill: currentcolor"
          d="M278.63,391.39h5.49l12.12,28.29H290l-2.63-6.41h-12.2l-2.63,6.41h-6.08ZM285.25,408l-3.95-9.53-4,9.53Z"
        />
        <path
          style="fill: currentcolor"
          d="M307.47,391.58h5.92v22.7H327.6v5.4H307.47Z"
        />
        <path
          style="fill: currentcolor"
          d="M339.1,391.58H345v22.7h14.21v5.4H339.1Z"
        />
        <path
          style="fill: currentcolor"
          d="M369.33,404.43h12.31v5.68H369.33Z"
        />
        <path style="fill: currentcolor" d="M394.14,391.58h6v28.1h-6Z" />
        <path
          style="fill: currentcolor"
          d="M414,391.58h5.46l13.59,17.72V391.58h5.88v28.1h-5.07l-14-18.3v18.3H414Z"
        />
        <path
          style="fill: currentcolor"
          d="M451.28,405.71v-.08A14.29,14.29,0,0,1,466,391.11c5.3,0,8.47,1.74,11.11,4.33l-3.8,4.36c-2.2-2-4.45-3.24-7.35-3.24-4.88,0-8.4,4.05-8.4,9v.08c0,4.94,3.44,9.07,8.4,9.07,3.29,0,5.3-1.31,7.55-3.36l3.79,3.83a14.52,14.52,0,0,1-11.54,5A14.19,14.19,0,0,1,451.28,405.71Z"
        />
        <path
          style="fill: currentcolor"
          d="M489,391.58H495v22.7h14.21v5.4H489Z"
        />
        <path
          style="fill: currentcolor"
          d="M520.19,407.68v-16.1h6v15.9c0,4.71,2.37,7.18,6.27,7.18s6.31-2.35,6.31-7V391.58h5.93v15.86c0,8.5-4.76,12.67-12.31,12.67S520.19,415.9,520.19,407.68Z"
        />
        <path
          style="fill: currentcolor"
          d="M555.89,415.59l3.52-4.21a12.94,12.94,0,0,0,8.51,3.48c2.64,0,4.26-1.12,4.26-2.86v-.08c0-1.66-1-2.51-5.8-3.7-5.77-1.43-9.37-3.05-9.37-8.57v-.08c0-5,4.1-8.38,9.83-8.38a16.29,16.29,0,0,1,10.41,3.55l-3.13,4.48a13,13,0,0,0-7.4-2.82c-2.47,0-3.79,1.2-3.79,2.63v.08c0,1.93,1.24,2.58,6.19,3.86,5.77,1.46,9,3.55,9,8.37v.08c0,5.52-4.26,8.65-10.3,8.65A17.76,17.76,0,0,1,555.89,415.59Z"
        />
        <path style="fill: currentcolor" d="M590.61,391.58h6v28.1h-6Z" />
        <path
          style="fill: currentcolor"
          d="M608,391.58h6.58l7.59,20.15,7.59-20.15h6.42l-11.46,28.3h-5.26Z"
        />
        <path
          style="fill: currentcolor"
          d="M647.4,391.58h21.18v5.29H653.29v6h13.47v5.29H653.29v6.21h15.48v5.29H647.4Z"
        />
        <path
          style="fill: currentcolor"
          d="M699.93,391.58h12.82c3.56,0,6.35,1,8.2,2.85a8.73,8.73,0,0,1,2.37,6.34v.07a8.67,8.67,0,0,1-6.16,8.69l7.05,10.15h-7L711,410.53h-5.15v9.15h-5.93Zm12.43,13.7c3.1,0,5-1.66,5-4.13v-.08c0-2.7-1.94-4.13-5.08-4.13h-6.38v8.34Z"
        />
        <path
          style="fill: currentcolor"
          d="M735.78,391.58H757v5.29H741.67v6h13.47v5.29H741.67v6.21h15.48v5.29H735.78Z"
        />
        <path
          style="fill: currentcolor"
          d="M767.56,415.59l3.53-4.21a12.94,12.94,0,0,0,8.51,3.48c2.64,0,4.26-1.12,4.26-2.86v-.08c0-1.66-1-2.51-5.8-3.7-5.77-1.43-9.37-3.05-9.37-8.57v-.08c0-5,4.1-8.38,9.83-8.38a16.29,16.29,0,0,1,10.41,3.55l-3.13,4.48a13,13,0,0,0-7.4-2.82c-2.47,0-3.79,1.2-3.79,2.63v.08c0,1.93,1.24,2.58,6.19,3.86,5.77,1.46,9,3.55,9,8.37v.08c0,5.52-4.26,8.65-10.3,8.65A17.8,17.8,0,0,1,767.56,415.59Z"
        />
        <path
          style="fill: currentcolor"
          d="M800.74,405.71v-.08c0-7.95,6.31-14.52,15-14.52s14.91,6.45,14.91,14.44v.08c0,7.95-6.31,14.52-15,14.52S800.74,413.7,800.74,405.71Zm23.65,0v-.08a8.78,8.78,0,0,0-8.75-9.07,8.65,8.65,0,0,0-8.67,9v.08a8.78,8.78,0,0,0,8.75,9.07A8.65,8.65,0,0,0,824.39,405.71Z"
        />
        <path
          style="fill: currentcolor"
          d="M843,391.58h12.81c3.56,0,6.35,1,8.21,2.85a8.73,8.73,0,0,1,2.36,6.34v.07a8.67,8.67,0,0,1-6.16,8.69l7,10.15h-7l-6.23-9.15H848.9v9.15H843Zm12.42,13.7c3.1,0,5-1.66,5-4.13v-.08c0-2.7-1.94-4.13-5.07-4.13H848.9v8.34Z"
        />
        <path
          style="fill: currentcolor"
          d="M885.44,397.06h-8.67v-5.48H900v5.48h-8.63v22.62h-6Z"
        />
        <path
          style="fill: currentcolor"
          d="M909.68,415.59l3.52-4.21a13,13,0,0,0,8.52,3.48c2.63,0,4.26-1.12,4.26-2.86v-.08c0-1.66-1-2.51-5.81-3.7-5.77-1.43-9.37-3.05-9.37-8.57v-.08c0-5,4.11-8.38,9.84-8.38a16.31,16.31,0,0,1,10.41,3.55l-3.14,4.48a13,13,0,0,0-7.39-2.82c-2.48,0-3.79,1.2-3.79,2.63v.08c0,1.93,1.23,2.58,6.19,3.86,5.77,1.46,9,3.55,9,8.37v.08c0,5.52-4.26,8.65-10.3,8.65A17.79,17.79,0,0,1,909.68,415.59Z"
        />
      </g>
    </g>
    <g v-else-if="logoType === 3" id="Layer_3">
      <g style="display: inline">
        <path
          style="fill: currentcolor"
          d="M79.31,33.48h-2.17l-5.56,12.97h2.54l1.26-3.05h5.64l1.23,3.05h2.61L79.31,33.48z M80.11,41.17h-3.83
            l1.91-4.58L80.11,41.17z"
        />
        <path
          style="fill: currentcolor"
          d="M50.43,33.58h-4.7v12.87h4.7c4.09,0,6.65-2.85,6.65-6.45C57.08,36.84,54.89,33.58,50.43,33.58 M50.43,44.16
            h-2.28v-8.32h2.28v0c2.51,0.01,4.14,1.76,4.14,4.18C54.57,42.39,52.97,44.16,50.43,44.16"
        />
        <polygon
          style="fill: currentcolor"
          points="24.28,38.65 21.25,33.58 18.41,33.58 23.04,41.14 23.04,46.45 25.47,46.45 25.47,41.08 30.12,33.58
            27.35,33.58"
        />
        <polygon
          style="fill: currentcolor"
          points="13.78,46.53 17.47,33.58 14.94,33.58 12.62,42.39 9.95,33.54 7.62,33.54 4.91,42.39 2.6,33.58
            0,33.58 3.73,46.53 5.88,46.53 8.7,37.33 11.58,46.53"
        />
        <polygon
          style="fill: currentcolor"
          points="40.1,42.03 33.72,33.58 31.53,33.58 31.53,39.86 31.53,46.45 33.91,46.45 33.91,37.72 40.48,46.45
            42.47,46.45 42.47,40.03 42.47,33.58 40.1,33.58"
        />
        <polygon
          style="fill: currentcolor"
          points="69.87,46.44 69.87,39.86 69.87,33.57 67.47,33.57 67.47,38.81 61.87,38.81 61.87,33.57 59.45,33.57
            59.45,39.68 59.45,46.44 61.87,46.44 61.87,41.13 67.47,41.13 67.47,46.44"
        />
        <polygon
          style="fill: currentcolor"
          points="92.48,39.42 88.82,33.57 86.33,33.57 86.33,39.51 86.33,46.44 88.7,46.44 88.7,37.74 92.35,43.34
            92.55,43.34 96.23,37.7 96.23,46.44 98.64,46.44 98.64,39.33 98.64,33.57 96.14,33.57"
        />
        <path
          style="fill: currentcolor"
          d="M39.99,81.67h2.71c0.62,0,1.09,0.18,1.41,0.54c0.32,0.36,0.48,0.82,0.48,1.39c0,0.37-0.08,0.7-0.24,0.99
            c-0.16,0.29-0.38,0.51-0.67,0.68c-0.29,0.16-0.63,0.24-1.02,0.24h-1.14v1.41h-1.53V81.67z M42.32,84.33c0.23,0,0.4-0.07,0.53-0.2
            c0.12-0.13,0.18-0.31,0.18-0.53c0-0.24-0.05-0.43-0.17-0.56c-0.11-0.13-0.28-0.2-0.5-0.2h-0.83v1.49H42.32z"
        />
        <polygon
          style="fill: currentcolor"
          points="46.39,81.67 47.93,81.67 47.93,85.75 50.29,85.75 50.29,86.92 46.39,86.92"
        />
        <path
          style="fill: currentcolor"
          d="M53.94,81.67h1.62l2.01,5.25h-1.6l-0.44-1.12h-1.58l-0.43,1.12h-1.6L53.94,81.67z M55.34,84.7l-0.59-1.54
            l-0.59,1.54H55.34z"
        />
        <polygon
          style="fill: currentcolor"
          points="61.27,84.99 59.38,81.67 60.98,81.67 62.04,83.65 63.1,81.67 64.7,81.67 62.81,84.99 62.81,86.92
            61.27,86.92"
        />
        <path
          style="fill: currentcolor"
          d="M68.51,81.67h1.62l2.01,5.25h-1.6l-0.44-1.12h-1.58l-0.43,1.12h-1.6L68.51,81.67z M69.91,84.7l-0.59-1.54
            l-0.59,1.54H69.91z"
        />
        <path
          style="fill: currentcolor"
          d="M78.28,81.67h2.35c0.84,0,1.47,0.21,1.9,0.63c0.43,0.42,0.65,1.1,0.65,2.03c0,0.89-0.22,1.54-0.65,1.96
            c-0.43,0.42-1.07,0.63-1.9,0.63h-2.35V81.67z M80.43,85.75c0.27,0,0.49-0.04,0.66-0.12c0.17-0.08,0.3-0.22,0.39-0.42
            c0.09-0.2,0.13-0.5,0.13-0.88c0-0.39-0.04-0.69-0.12-0.9c-0.08-0.21-0.21-0.37-0.38-0.45c-0.17-0.09-0.4-0.13-0.68-0.13h-0.62v2.9
            H80.43z"
        />
        <polygon
          style="fill: currentcolor"
          points="85.15,81.67 89.13,81.67 89.13,82.84 86.68,82.84 86.68,83.7 88.82,83.7 88.82,84.89 86.68,84.89
            86.68,85.75 89.13,85.75 89.13,86.92 85.15,86.92"
        />
        <polygon
          style="fill: currentcolor"
          points="91.01,81.67 92.54,81.67 92.54,85.75 94.91,85.75 94.91,86.92 91.01,86.92"
        />
        <path
          style="fill: currentcolor"
          d="M101.46,86.3c-0.5-0.46-0.75-1.13-0.75-1.99c0-0.88,0.25-1.56,0.74-2.03c0.49-0.47,1.22-0.7,2.18-0.7
            c0.31,0,0.59,0.02,0.84,0.07c0.25,0.05,0.51,0.12,0.76,0.21v1.34c-0.47-0.21-0.97-0.31-1.5-0.31c-0.5,0-0.87,0.11-1.1,0.34
            c-0.24,0.23-0.36,0.59-0.36,1.08c0,0.48,0.12,0.83,0.37,1.05c0.25,0.22,0.62,0.33,1.1,0.33c0.54,0,1.04-0.1,1.5-0.3v1.35
            c-0.51,0.17-1.04,0.26-1.62,0.26C102.69,87,101.96,86.77,101.46,86.3"
        />
        <path
          style="fill: currentcolor"
          d="M109.07,81.67h1.62l2.01,5.25h-1.6l-0.44-1.12h-1.58l-0.43,1.12h-1.6L109.07,81.67z M110.47,84.7l-0.59-1.54
            l-0.59,1.54H110.47z"
        />
        <path
          style="fill: currentcolor"
          d="M114.67,81.67h2.73c0.62,0,1.09,0.17,1.4,0.51s0.47,0.79,0.47,1.36c0,0.35-0.09,0.66-0.26,0.93
            c-0.17,0.27-0.41,0.48-0.71,0.62c0.06,0.05,0.11,0.11,0.15,0.17c0.04,0.07,0.09,0.16,0.15,0.29l0.6,1.38h-1.6l-0.55-1.26
            c-0.04-0.1-0.1-0.17-0.16-0.21c-0.06-0.04-0.15-0.06-0.26-0.06h-0.43v1.53h-1.53V81.67z M117.01,84.21c0.22,0,0.4-0.06,0.52-0.17
            c0.12-0.12,0.18-0.28,0.18-0.5c0-0.46-0.22-0.69-0.65-0.69h-0.86v1.37H117.01z"
        />
        <polygon
          style="fill: currentcolor"
          points="121.25,81.67 122.74,81.67 124.04,84.33 125.34,81.67 126.82,81.67 126.82,86.92 125.29,86.92
            125.29,84.29 124.45,86.02 123.63,86.02 122.78,84.29 122.78,86.92 121.25,86.92"
        />
        <polygon
          style="fill: currentcolor"
          points="128.95,81.67 132.93,81.67 132.93,82.84 130.48,82.84 130.48,83.7 132.63,83.7 132.63,84.89
            130.48,84.89 130.48,85.75 132.93,85.75 132.93,86.92 128.95,86.92"
        />
        <polygon
          style="fill: currentcolor"
          points="134.81,81.67 136.12,81.67 138.1,84.45 138.1,81.67 139.63,81.67 139.63,86.92 138.32,86.92
            136.35,84.15 136.35,86.92 134.81,86.92"
        />
        <path
          style="fill: currentcolor"
          d="M2.66,68.96h1.03l2.26,5.28H4.78l-0.49-1.2H2.01l-0.49,1.2H0.39L2.66,68.96z M3.89,72.07l-0.74-1.78
            l-0.74,1.78H3.89z"
        />
        <polygon
          style="fill: currentcolor"
          points="8.47,69 9.58,69 9.58,73.24 12.23,73.24 12.23,74.25 8.47,74.25"
        />
        <polygon
          style="fill: currentcolor"
          points="14.81,69 15.92,69 15.92,73.24 18.57,73.24 18.57,74.25 14.81,74.25"
        />
        <rect
          x="20.89"
          y="71.4"
          style="fill: currentcolor"
          width="2.3"
          height="1.06"
        />
        <rect
          x="25.95"
          y="69"
          style="fill: currentcolor"
          width="1.11"
          height="5.25"
        />
        <polygon
          style="fill: currentcolor"
          points="30.09,69 31.11,69 33.65,72.31 33.65,69 34.75,69 34.75,74.25 33.8,74.25 31.19,70.83 31.19,74.25
            30.09,74.25"
        />
        <path
          style="fill: currentcolor"
          d="M37.49,71.64v-0.01c0-1.49,1.13-2.71,2.74-2.71c0.99,0,1.58,0.32,2.07,0.81l-0.71,0.82
            c-0.41-0.38-0.83-0.61-1.37-0.61c-0.91,0-1.57,0.76-1.57,1.68v0.02c0,0.92,0.64,1.69,1.57,1.69c0.61,0,0.99-0.24,1.41-0.63
            l0.71,0.71c-0.54,0.57-1.14,0.93-2.15,0.93C38.64,74.33,37.49,73.14,37.49,71.64"
        />
        <polygon
          style="fill: currentcolor"
          points="44.97,69 46.07,69 46.07,73.24 48.72,73.24 48.72,74.25 44.97,74.25"
        />
        <path
          style="fill: currentcolor"
          d="M51.22,72v-3h1.11v2.97c0,0.88,0.44,1.34,1.17,1.34c0.73,0,1.18-0.44,1.18-1.3V69h1.11v2.96
            c0,1.59-0.89,2.36-2.3,2.36C52.08,74.32,51.22,73.54,51.22,72"
        />
        <path
          style="fill: currentcolor"
          d="M58.31,73.48l0.66-0.78c0.48,0.4,0.98,0.65,1.59,0.65c0.49,0,0.8-0.21,0.8-0.53V72.8
            c0-0.31-0.19-0.47-1.08-0.69c-1.08-0.27-1.75-0.57-1.75-1.6v-0.01c0-0.94,0.77-1.56,1.84-1.56c0.77,0,1.41,0.24,1.94,0.66
            l-0.59,0.84c-0.46-0.33-0.92-0.53-1.38-0.53c-0.46,0-0.71,0.22-0.71,0.49v0.01c0,0.36,0.23,0.48,1.16,0.72
            c1.08,0.27,1.68,0.66,1.68,1.56v0.02c0,1.03-0.79,1.61-1.92,1.61C59.74,74.32,58.94,74.04,58.31,73.48"
        />
        <rect
          x="65.23"
          y="69"
          style="fill: currentcolor"
          width="1.11"
          height="5.25"
        />
        <polygon
          style="fill: currentcolor"
          points="68.91,69 70.14,69 71.56,72.76 72.97,69 74.17,69 72.03,74.28 71.05,74.28"
        />
        <polygon
          style="fill: currentcolor"
          points="76.7,69 80.65,69 80.65,69.99 77.79,69.99 77.79,71.11 80.31,71.11 80.31,72.1 77.79,72.1
            77.79,73.26 80.68,73.26 80.68,74.25 76.7,74.25"
        />
        <path
          style="fill: currentcolor"
          d="M89.18,68.96h1.03l2.26,5.28h-1.16l-0.49-1.2h-2.28l-0.49,1.2h-1.13L89.18,68.96z M90.42,72.07l-0.74-1.78
            l-0.74,1.78H90.42z"
        />
        <path
          style="fill: currentcolor"
          d="M95,69h2.03c1.65,0,2.8,1.13,2.8,2.61v0.01c0,1.48-1.14,2.62-2.8,2.62H95V69z M96.1,70v3.24h0.92
            c0.98,0,1.63-0.66,1.63-1.61v-0.02c0-0.95-0.66-1.62-1.63-1.62H96.1z"
        />
        <path
          style="fill: currentcolor"
          d="M102.48,72v-3h1.11v2.97c0,0.88,0.44,1.34,1.17,1.34c0.73,0,1.18-0.44,1.18-1.3V69h1.11v2.96
            c0,1.59-0.89,2.36-2.3,2.36C103.34,74.32,102.48,73.54,102.48,72"
        />
        <polygon
          style="fill: currentcolor"
          points="109.93,69 111.04,69 111.04,73.24 113.69,73.24 113.69,74.25 109.93,74.25"
        />
        <polygon
          style="fill: currentcolor"
          points="117.51,70.02 115.89,70.02 115.89,69 120.23,69 120.23,70.02 118.62,70.02 118.62,74.25
            117.51,74.25"
        />
        <path
          style="fill: currentcolor"
          d="M122.46,73.48l0.66-0.78c0.48,0.4,0.98,0.65,1.59,0.65c0.49,0,0.8-0.21,0.8-0.53V72.8
            c0-0.31-0.19-0.47-1.08-0.69c-1.08-0.27-1.75-0.57-1.75-1.6v-0.01c0-0.94,0.77-1.56,1.84-1.56c0.77,0,1.41,0.24,1.94,0.66
            l-0.59,0.84c-0.46-0.33-0.92-0.53-1.38-0.53c-0.46,0-0.71,0.22-0.71,0.49v0.01c0,0.36,0.23,0.48,1.16,0.72
            c1.08,0.27,1.68,0.66,1.68,1.56v0.02c0,1.03-0.79,1.61-1.92,1.61C123.89,74.32,123.09,74.04,122.46,73.48"
        />
        <path
          style="fill: currentcolor"
          d="M133.28,69h2.39c0.67,0,1.18,0.19,1.53,0.53c0.29,0.29,0.44,0.7,0.44,1.18v0.01c0,0.85-0.47,1.38-1.15,1.62
            l1.32,1.89h-1.3l-1.16-1.71h-0.96v1.71h-1.1V69z M135.59,71.56c0.58,0,0.92-0.31,0.92-0.77v-0.01c0-0.51-0.36-0.77-0.95-0.77
            h-1.19v1.56H135.59z"
        />
        <polygon
          style="fill: currentcolor"
          points="140.4,69 144.35,69 144.35,69.99 141.5,69.99 141.5,71.11 144.01,71.11 144.01,72.1 141.5,72.1
            141.5,73.26 144.39,73.26 144.39,74.25 140.4,74.25"
        />
        <path
          style="fill: currentcolor"
          d="M146.77,73.48l0.66-0.78c0.48,0.4,0.98,0.65,1.59,0.65c0.49,0,0.8-0.21,0.8-0.53V72.8
            c0-0.31-0.19-0.47-1.08-0.69c-1.08-0.27-1.75-0.57-1.75-1.6v-0.01c0-0.94,0.77-1.56,1.84-1.56c0.77,0,1.41,0.24,1.94,0.66
            l-0.59,0.84c-0.46-0.33-0.92-0.53-1.38-0.53c-0.46,0-0.71,0.22-0.71,0.49v0.01c0,0.36,0.23,0.48,1.16,0.72
            c1.08,0.27,1.68,0.66,1.68,1.56v0.02c0,1.03-0.79,1.61-1.92,1.61C148.19,74.32,147.39,74.04,146.77,73.48"
        />
        <path
          style="fill: currentcolor"
          d="M153.39,71.64v-0.01c0-1.49,1.18-2.71,2.8-2.71c1.62,0,2.78,1.2,2.78,2.7v0.02c0,1.48-1.18,2.71-2.8,2.71
            C154.56,74.33,153.39,73.13,153.39,71.64 M157.81,71.64v-0.01c0-0.92-0.68-1.69-1.63-1.69c-0.95,0-1.62,0.76-1.62,1.68v0.02
            c0,0.92,0.68,1.69,1.63,1.69C157.14,73.32,157.81,72.56,157.81,71.64"
        />
        <path
          style="fill: currentcolor"
          d="M161.71,69h2.39c0.67,0,1.18,0.19,1.53,0.53c0.29,0.29,0.44,0.7,0.44,1.18v0.01c0,0.85-0.47,1.38-1.15,1.62
            l1.32,1.89h-1.3l-1.16-1.71h-0.96v1.71h-1.11V69z M164.03,71.56c0.58,0,0.92-0.31,0.92-0.77v-0.01c0-0.51-0.36-0.77-0.95-0.77
            h-1.19v1.56H164.03z"
        />
        <polygon
          style="fill: currentcolor"
          points="170.07,70.02 168.45,70.02 168.45,69 172.79,69 172.79,70.02 171.18,70.02 171.18,74.25
            170.07,74.25"
        />
        <path
          style="fill: currentcolor"
          d="M175.03,73.48l0.66-0.78c0.48,0.4,0.98,0.65,1.59,0.65c0.49,0,0.8-0.21,0.8-0.53V72.8
            c0-0.31-0.19-0.47-1.08-0.69c-1.08-0.27-1.75-0.57-1.75-1.6v-0.01c0-0.94,0.77-1.56,1.84-1.56c0.77,0,1.41,0.24,1.94,0.66
            l-0.58,0.84c-0.46-0.33-0.93-0.53-1.38-0.53c-0.46,0-0.71,0.22-0.71,0.49v0.01c0,0.36,0.23,0.48,1.16,0.72
            c1.08,0.27,1.68,0.66,1.68,1.56v0.02c0,1.03-0.79,1.61-1.92,1.61C176.45,74.32,175.66,74.04,175.03,73.48"
        />
        <path
          style="fill: currentcolor"
          d="M165.11,52.33c-9.48,5.16-16.92,6.1-21.52,2.72c-3.59-2.64-4.78-7.51-4.47-11.54c1.87-2.54,3.44-5.4,4.17-6.8
            c1.9-3.65,3.49-7.52,4.36-10.63c1.06-3.81,1.02-6.15-0.12-7.16c-0.52-0.45-1.13-0.59-1.73-0.38c-1.11,0.39-2.88,2.07-5.47,12.18
            c-1.39,5.42-2.36,10.75-2.36,10.79c-0.1,0.49-0.17,0.98-0.22,1.48c-2.16,2.84-3.95,4.14-4.93,3.92c-0.99-0.3-1.52-1.47-0.91-6.13
            l0.04-0.33c0-0.02,0.01-0.04,0.01-0.07c5.05-6.09,7.45-14,7.02-17.45c-0.18-1.41-0.79-1.93-1.28-2.12
            c-0.57-0.22-1.15-0.16-1.7,0.16c-2.15,1.28-3.86,7.26-5.39,18.81c-1.09,1.28-2.3,2.46-3.63,3.49c-0.13-1.55-0.21-2.96-0.26-4.14
            c-0.21-4.78-0.09-8.72,0.12-11.19c1.09-2.93,0.8-3.36,0.63-3.6c-0.18-0.26-0.49-0.39-0.81-0.34c-0.01,0-0.02,0-0.03,0.01
            c-0.18,0.04-0.43,0.15-0.62,0.5c-0.24,0.45-0.43,1.57-0.57,3.13c-1.18,3.09-3.91,9.06-9.52,19.34c-2.82-0.11-5.76-0.93-8.8-2.46
            c-0.35-0.18-0.78-0.04-0.95,0.32c-0.18,0.35-0.04,0.78,0.32,0.95c2.96,1.49,5.88,2.34,8.7,2.55c-1.18,2.14-2.48,4.46-3.9,6.98
            c-0.19,0.34-0.07,0.77,0.27,0.97c0.34,0.19,0.77,0.07,0.97-0.27c1.59-2.79,3-5.33,4.26-7.63c3.16-0.04,6.19-0.9,9.02-2.58
            c0.52,4.97,1.51,10.82,3.39,15.33c0.11,0.27,0.38,0.44,0.66,0.44c0.09,0,0.18-0.02,0.27-0.05c0.36-0.15,0.53-0.57,0.38-0.93
            c-1.91-4.59-2.88-10.65-3.36-15.66c1.15-0.81,2.21-1.73,3.19-2.72c-0.3,3.32,0.07,5.47,2.11,6.08c0.01,0,0.02,0.01,0.03,0.01
            c1.62,0.4,3.47-0.98,5.2-2.94c0.04,1.35,0.24,2.7,0.59,3.97c0.8,2.93,2.36,5.31,4.5,6.88c2.2,1.62,4.78,2.27,7.5,2.27
            c5.22,0,10.96-2.39,15.54-4.88c0.34-0.19,0.47-0.62,0.28-0.96C165.88,52.27,165.45,52.15,165.11,52.33 M141.71,31.07
            c2.51-9.77,4.12-11.03,4.56-11.18c0.02-0.01,0.05-0.02,0.08-0.02c0.05,0,0.13,0.02,0.24,0.12c0.5,0.44,0.68,2.17-0.3,5.71
            c-0.84,3.02-2.39,6.79-4.25,10.36c-0.81,1.55-1.6,2.93-2.37,4.15C140.04,38.24,140.78,34.67,141.71,31.07 M133.6,30.24
            c1.33-6.18,2.52-7.69,3.11-8.05c0.1-0.06,0.19-0.09,0.28-0.09c0.06,0,0.12,0.01,0.18,0.04c0.1,0.04,0.3,0.29,0.38,0.97
            c0.35,2.79-1.48,9.02-5.22,14.4C132.64,35.4,133.06,32.76,133.6,30.24 M117.54,46.95c3.68-6.79,5.94-11.38,7.28-14.29
            c0.14-0.3,0.27-0.6,0.4-0.88c-0.05,2.27-0.03,4.85,0.08,7.42c0.06,1.39,0.16,3.11,0.33,5.01
            C123.07,45.87,120.37,46.78,117.54,46.95"
        />
        <path
          style="fill: currentcolor"
          d="M179,43.76c-0.26-0.3-0.7-0.33-1-0.08c-4.56,3.93-6.2,3.35-6.59,3.08c-0.71-0.49-1.05-2.12-0.31-5.26
            c0.07-0.21,0.14-0.43,0.21-0.66c0.02-0.08,0.03-0.16,0.03-0.24c0.2-0.71,0.45-1.49,0.75-2.34c0.13-0.37-0.06-0.77-0.43-0.91
            c-0.37-0.13-0.78,0.06-0.91,0.43c-0.03,0.09-0.06,0.18-0.1,0.27c-1.52-0.66-2.93-0.67-4.18-0.03c-1.26,0.64-2.14,1.84-2.73,3
            c-0.03,0.04-0.06,0.09-0.08,0.14c-0.01,0.02-0.79,1.8-1.9,3.39c-0.73,1.06-2.12,2.78-3.19,2.31c-0.85-0.37-0.28-2.63,0.18-4.44
            c0.18-0.71,0.36-1.43,0.49-2.12c0.19-0.88,0.26-1.59,0.04-2.02c-0.48-0.95-0.98-0.93-2.27-1.22l-0.39-0.04l-0.09-0.38
            c-0.12-0.52-0.19-0.69-0.22-0.74c-0.35-0.83-0.79-0.92-1.13-0.91c-0.3,0-0.53,0.12-0.75,0.36c-0.23,0.25-0.35,0.56-0.36,0.92
            c-0.03,0.47,0.1,0.87,0.37,1.21c-0.3,1.02-0.93,2.97-1.78,4.84c-1.48,3.23-3,4.85-4.28,4.59c-0.33-0.09-0.57-0.3-0.74-0.67
            c-1.03-2.19,0.62-7.98,2.55-13.19c0.42-0.03,0.93-0.07,0.93-0.08c4.11-0.32,12.19-1.04,13.98-1.26c0.29-0.06,0.48-0.21,0.58-0.44
            c0.1-0.24,0.1-0.46,0.01-0.67c-0.1-0.21-0.29-0.31-0.59-0.29c-5.9,0.71-7.74,0.9-13.68,1.27c0,0-0.28,0-0.67,0.01
            c1.03-2.66,2.08-5.06,2.79-6.58c0.17-0.35,0.01-0.78-0.34-0.94c-0.35-0.17-0.78-0.01-0.94,0.34c-0.02,0.04-1.53,3.26-3.05,7.26
            c-0.39,0.03-0.73,0.08-0.91,0.15c-0.23,0.1-0.37,0.26-0.44,0.48c-0.07,0.22-0.05,0.43,0.05,0.62c0.1,0.19,0.29,0.28,0.57,0.27
            c0.07-0.01,0.12-0.01,0.17-0.01c-0.06,0.16-0.11,0.32-0.17,0.48c-2.42,6.76-3.11,11.07-2.11,13.19c0.35,0.75,0.93,1.25,1.68,1.44
            l0.03,0.01c0.2,0.05,0.4,0.07,0.6,0.07c3.63,0,6.16-7.19,7.05-10.13l0.98,0.18l0.63,0.08c0.77,0.06,0.57,0.73,0.5,1.27
            c-0.08,0.6-0.26,1.56-0.74,3.56h0c-0.49,2.14-0.7,4.12,0.94,4.84c0.32,0.14,0.63,0.2,0.94,0.2c1.28,0,2.49-1.06,3.49-2.33
            c0.04,0.97,0.39,1.65,1.04,2.05c0.34,0.21,0.73,0.3,1.15,0.3c1.4,0,3.12-1.02,4.15-2.29c0.07-0.08,0.27-0.3,0.53-0.7
            c0.14,1.19,0.57,2.04,1.3,2.55c0.45,0.31,0.97,0.46,1.57,0.46c1.68,0,3.94-1.21,6.75-3.63C179.22,44.5,179.26,44.06,179,43.76
             M167.74,45.12l-0.05,0.05c-1.08,1.35-2.85,2.07-3.48,1.69c-0.53-0.32-0.64-1.88,0.81-5.21c0.02-0.04,0.03-0.08,0.04-0.12
            c0.48-0.9,1.15-1.8,2.05-2.26c0.9-0.46,1.91-0.42,3.09,0.12c-0.19,0.6-0.34,1.18-0.48,1.72
            C168.79,43.99,167.74,45.11,167.74,45.12"
        />
      </g>
    </g>
    <g v-if="logoType === 4" id="Layer_4">
      <rect x="-413.41" y="-253.17" class="st3" width="1006" height="612" />
      <g style="display: inline">
        <path
          style="fill: currentcolor"
          d="M50.42,97.76v-0.01c0-1.37,1.03-2.49,2.51-2.49c0.91,0,1.45,0.3,1.9,0.74l-0.65,0.75
            c-0.38-0.35-0.76-0.56-1.26-0.56c-0.84,0-1.44,0.7-1.44,1.55v0.01c0,0.85,0.59,1.56,1.44,1.56c0.56,0,0.91-0.23,1.29-0.58
            l0.65,0.66c-0.5,0.52-1.05,0.86-1.98,0.86C51.48,100.24,50.42,99.15,50.42,97.76"
        />
        <polygon
          style="fill: currentcolor"
          points="56.12,95.34 57.13,95.34 57.13,99.24 59.57,99.24 59.57,100.16 56.12,100.16"
        />
        <path
          style="fill: currentcolor"
          d="M60.69,98.1v-2.77h1.02v2.73c0,0.81,0.4,1.23,1.07,1.23c0.67,0,1.08-0.4,1.08-1.19v-2.77h1.01v2.73
            c0,1.46-0.82,2.18-2.11,2.18C61.49,100.24,60.69,99.51,60.69,98.1"
        />
        <path
          style="fill: currentcolor"
          d="M66.03,99.46l0.6-0.72c0.44,0.37,0.9,0.6,1.46,0.6c0.45,0,0.73-0.19,0.73-0.49v-0.01
            c0-0.29-0.17-0.43-0.99-0.64c-0.99-0.24-1.61-0.52-1.61-1.47v-0.01c0-0.86,0.7-1.44,1.69-1.44c0.7,0,1.29,0.22,1.78,0.61
            l-0.54,0.77c-0.42-0.3-0.85-0.48-1.27-0.48c-0.42,0-0.65,0.21-0.65,0.45v0.01c0,0.33,0.21,0.44,1.06,0.66
            c0.99,0.25,1.54,0.61,1.54,1.44v0.01c0,0.95-0.73,1.48-1.76,1.48C67.34,100.23,66.61,99.98,66.03,99.46"
        />
        <rect
          x="71.21"
          y="95.34"
          style="fill: currentcolor"
          width="1.02"
          height="4.83"
        />
        <polygon
          style="fill: currentcolor"
          points="73.43,95.34 74.56,95.34 75.86,98.8 77.16,95.34 78.26,95.34 76.29,100.2 75.39,100.2"
        />
        <polygon
          style="fill: currentcolor"
          points="79.4,95.34 83.03,95.34 83.03,96.24 80.41,96.24 80.41,97.28 82.72,97.28 82.72,98.19 80.41,98.19
            80.41,99.26 83.06,99.26 83.06,100.16 79.4,100.16"
        />
        <path
          style="fill: currentcolor"
          d="M88.53,95.3h0.94l2.08,4.86h-1.07l-0.45-1.1h-2.09l-0.45,1.1h-1.04L88.53,95.3z M89.66,98.16l-0.68-1.64
            l-0.68,1.64H89.66z"
        />
        <path
          style="fill: currentcolor"
          d="M92.7,95.34h1.86c1.52,0,2.57,1.04,2.57,2.4v0.01c0,1.36-1.05,2.41-2.57,2.41H92.7V95.34z M93.72,96.26v2.99
            h0.85c0.89,0,1.5-0.6,1.5-1.48v-0.01c0-0.88-0.6-1.49-1.5-1.49H93.72z"
        />
        <path
          style="fill: currentcolor"
          d="M98.4,98.1v-2.77h1.02v2.73c0,0.81,0.4,1.23,1.07,1.23s1.08-0.4,1.08-1.19v-2.77h1.01v2.73
            c0,1.46-0.82,2.18-2.11,2.18C99.19,100.24,98.4,99.51,98.4,98.1"
        />
        <polygon
          style="fill: currentcolor"
          points="104.07,95.34 105.09,95.34 105.09,99.24 107.52,99.24 107.52,100.16 104.07,100.16"
        />
        <polygon
          style="fill: currentcolor"
          points="109.86,96.28 108.37,96.28 108.37,95.34 112.36,95.34 112.36,96.28 110.88,96.28 110.88,100.16
            109.86,100.16"
        />
        <path
          style="fill: currentcolor"
          d="M113.24,99.46l0.6-0.72c0.44,0.37,0.9,0.6,1.46,0.6c0.45,0,0.73-0.19,0.73-0.49v-0.01
            c0-0.29-0.17-0.43-0.99-0.64c-0.99-0.24-1.61-0.52-1.61-1.47v-0.01c0-0.86,0.7-1.44,1.69-1.44c0.7,0,1.29,0.22,1.78,0.61
            l-0.54,0.77c-0.42-0.3-0.85-0.48-1.27-0.48c-0.42,0-0.65,0.21-0.65,0.45v0.01c0,0.33,0.21,0.44,1.06,0.66
            c0.99,0.25,1.54,0.61,1.54,1.44v0.01c0,0.95-0.73,1.48-1.76,1.48C114.55,100.23,113.82,99.98,113.24,99.46"
        />
        <path
          style="fill: currentcolor"
          d="M120.83,95.33h2.2c0.61,0,1.09,0.17,1.41,0.49c0.27,0.27,0.41,0.64,0.41,1.09v0.01
            c0,0.78-0.43,1.27-1.06,1.49l1.21,1.74h-1.19l-1.07-1.57h-0.88v1.57h-1.01V95.33z M122.96,97.69c0.53,0,0.85-0.28,0.85-0.71v-0.01
            c0-0.46-0.33-0.71-0.87-0.71h-1.09v1.43H122.96z"
        />
        <polygon
          style="fill: currentcolor"
          points="126.2,95.34 129.83,95.34 129.83,96.24 127.21,96.24 127.21,97.28 129.51,97.28 129.51,98.19
            127.21,98.19 127.21,99.26 129.86,99.26 129.86,100.16 126.2,100.16"
        />
        <path
          style="fill: currentcolor"
          d="M130.87,99.46l0.6-0.72c0.44,0.37,0.9,0.6,1.46,0.6c0.45,0,0.73-0.19,0.73-0.49v-0.01
            c0-0.29-0.17-0.43-0.99-0.64c-0.99-0.24-1.61-0.52-1.61-1.47v-0.01c0-0.86,0.7-1.44,1.69-1.44c0.7,0,1.29,0.22,1.78,0.61
            L134,96.65c-0.42-0.3-0.85-0.48-1.27-0.48c-0.42,0-0.65,0.21-0.65,0.45v0.01c0,0.33,0.21,0.44,1.06,0.66
            c0.99,0.25,1.54,0.61,1.54,1.44v0.01c0,0.95-0.73,1.48-1.76,1.48C132.18,100.23,131.45,99.98,130.87,99.46"
        />
        <path
          style="fill: currentcolor"
          d="M135.79,97.76v-0.01c0-1.37,1.08-2.49,2.57-2.49c1.49,0,2.55,1.11,2.55,2.48v0.01c0,1.37-1.08,2.49-2.57,2.49
            C136.85,100.24,135.79,99.14,135.79,97.76 M139.84,97.76v-0.01c0-0.85-0.62-1.56-1.5-1.56c-0.88,0-1.49,0.7-1.49,1.55v0.01
            c0,0.85,0.62,1.56,1.5,1.56C139.23,99.31,139.84,98.61,139.84,97.76"
        />
        <path
          style="fill: currentcolor"
          d="M142.25,95.33h2.2c0.61,0,1.09,0.17,1.41,0.49c0.26,0.27,0.4,0.64,0.4,1.09v0.01c0,0.78-0.43,1.27-1.05,1.49
            l1.21,1.74h-1.19l-1.07-1.57h-0.88v1.57h-1.01V95.33z M144.38,97.69c0.53,0,0.85-0.28,0.85-0.71v-0.01c0-0.46-0.33-0.71-0.87-0.71
            h-1.09v1.43H144.38z"
        />
        <polygon
          style="fill: currentcolor"
          points="148.76,96.28 147.27,96.28 147.27,95.34 151.26,95.34 151.26,96.28 149.78,96.28 149.78,100.16
            148.76,100.16"
        />
        <path
          style="fill: currentcolor"
          d="M152.14,99.46l0.6-0.72c0.44,0.37,0.9,0.6,1.46,0.6c0.45,0,0.73-0.19,0.73-0.49v-0.01
            c0-0.29-0.17-0.43-0.99-0.64c-0.99-0.24-1.61-0.52-1.61-1.47v-0.01c0-0.86,0.7-1.44,1.69-1.44c0.7,0,1.29,0.22,1.78,0.61
            l-0.54,0.77c-0.42-0.3-0.85-0.48-1.27-0.48c-0.42,0-0.65,0.21-0.65,0.45v0.01c0,0.33,0.21,0.44,1.06,0.66
            c0.99,0.25,1.54,0.61,1.54,1.44v0.01c0,0.95-0.73,1.48-1.76,1.48C153.45,100.23,152.72,99.98,152.14,99.46"
        />
        <polygon
          style="fill: currentcolor"
          points="112.52,5.24 113.72,5.24 114.86,8.91 116.07,5.23 117.03,5.23 118.25,8.91 119.38,5.24 120.55,5.24
            118.73,10.6 117.76,10.6 116.54,7.06 115.32,10.6 114.34,10.6"
        />
        <polygon
          style="fill: currentcolor"
          points="122.84,8.47 120.79,5.24 122.11,5.24 123.41,7.42 124.75,5.24 126.02,5.24 123.97,8.45 123.97,10.57
            122.84,10.57"
        />
        <polygon
          style="fill: currentcolor"
          points="126.83,5.24 127.86,5.24 130.42,8.6 130.42,5.24 131.53,5.24 131.53,10.57 130.57,10.57 127.93,7.1
            127.93,10.57 126.83,10.57"
        />
        <path
          style="fill: currentcolor"
          d="M132.88,5.24h2.05c1.67,0,2.82,1.15,2.82,2.65V7.9c0,1.5-1.15,2.66-2.82,2.66h-2.05V5.24z M134,6.26v3.29
            h0.93c0.98,0,1.65-0.67,1.65-1.63V7.9c0-0.96-0.66-1.65-1.65-1.65H134z"
        />
        <polygon
          style="fill: currentcolor"
          points="138.86,5.24 139.98,5.24 139.98,7.37 142.21,7.37 142.21,5.24 143.33,5.24 143.33,10.57
            142.21,10.57 142.21,8.41 139.98,8.41 139.98,10.57 138.86,10.57"
        />
        <path
          style="fill: currentcolor"
          d="M146.51,5.21h1.04l2.28,5.36h-1.17l-0.5-1.21h-2.3l-0.5,1.21h-1.14L146.51,5.21z M147.76,8.36l-0.74-1.81
            l-0.75,1.81H147.76z"
        />
        <polygon
          style="fill: currentcolor"
          points="150.73,5.24 151.93,5.24 153.37,7.55 154.81,5.24 156.02,5.24 156.02,10.57 154.9,10.57 154.9,7.02
            153.37,9.35 153.34,9.35 151.82,7.04 151.82,10.57 150.73,10.57"
        />
        <path
          style="fill: currentcolor"
          d="M130.45,70.27c-17.22,9.39-30.75,11.1-39.12,4.94c-6.52-4.8-8.69-13.64-8.12-20.98
            c3.4-4.61,6.25-9.82,7.57-12.35c3.46-6.63,6.35-13.68,7.93-19.32c1.94-6.92,1.86-11.18-0.23-13.01c-0.94-0.82-2.05-1.07-3.14-0.69
            c-2.01,0.7-5.23,3.76-9.94,22.13c-2.53,9.85-4.28,19.54-4.3,19.61c-0.18,0.88-0.31,1.78-0.41,2.69c-3.93,5.16-7.18,7.53-8.97,7.12
            c-1.8-0.55-2.76-2.68-1.65-11.14l0.08-0.6c0-0.04,0.01-0.08,0.02-0.12c9.18-11.08,13.54-25.45,12.76-31.73
            c-0.32-2.57-1.44-3.51-2.33-3.86c-1.03-0.4-2.1-0.3-3.08,0.29c-3.9,2.33-7.02,13.2-9.81,34.19c-1.98,2.33-4.18,4.48-6.59,6.34
            c-0.23-2.82-0.37-5.38-0.47-7.52c-0.38-8.69-0.16-15.84,0.21-20.34c1.99-5.32,1.45-6.1,1.15-6.54c-0.33-0.48-0.89-0.72-1.47-0.63
            c-0.02,0-0.03,0.01-0.05,0.01c-0.33,0.07-0.79,0.27-1.12,0.9c-0.43,0.81-0.79,2.85-1.04,5.68c-2.15,5.61-7.11,16.46-17.31,35.16
            c-5.12-0.2-10.46-1.69-16-4.48c-0.64-0.32-1.41-0.06-1.73,0.57c-0.32,0.64-0.06,1.41,0.57,1.73c5.38,2.71,10.68,4.25,15.81,4.63
            c-2.14,3.9-4.5,8.12-7.1,12.68c-0.35,0.62-0.14,1.41,0.48,1.76c0.62,0.35,1.41,0.14,1.76-0.48c2.88-5.07,5.46-9.68,7.75-13.86
            c5.75-0.07,11.25-1.63,16.39-4.69c0.94,9.03,2.74,19.67,6.16,27.88c0.21,0.5,0.69,0.79,1.19,0.79c0.17,0,0.33-0.03,0.5-0.1
            c0.66-0.27,0.97-1.03,0.69-1.69c-3.48-8.34-5.23-19.36-6.11-28.46c2.08-1.47,4.02-3.14,5.8-4.95c-0.54,6.03,0.13,9.94,3.84,11.04
            c0.02,0.01,0.04,0.01,0.06,0.02c2.95,0.72,6.32-1.78,9.46-5.35c0.08,2.45,0.44,4.9,1.07,7.22c1.46,5.32,4.29,9.65,8.18,12.51
            c4,2.94,8.69,4.12,13.63,4.12c9.49,0,19.93-4.33,28.25-8.87c0.63-0.34,0.86-1.12,0.51-1.75
            C131.86,70.16,131.08,69.93,130.45,70.27 M87.91,31.61c4.56-17.76,7.5-20.05,8.29-20.32c0.04-0.01,0.09-0.03,0.15-0.03
            c0.1,0,0.24,0.05,0.44,0.22c0.91,0.8,1.24,3.95-0.56,10.38c-1.53,5.48-4.35,12.34-7.73,18.83c-1.47,2.82-2.92,5.34-4.31,7.55
            C84.89,44.65,86.23,38.16,87.91,31.61 M73.18,30.1c2.41-11.23,4.58-13.99,5.66-14.63c0.18-0.11,0.35-0.16,0.51-0.16
            c0.11,0,0.21,0.02,0.33,0.07c0.19,0.07,0.54,0.52,0.7,1.76c0.63,5.06-2.68,16.4-9.49,26.19C71.43,39.49,72.2,34.68,73.18,30.1
            M43.98,60.48c6.7-12.34,10.8-20.7,13.23-25.97c0.25-0.55,0.5-1.08,0.73-1.6c-0.1,4.13-0.06,8.82,0.14,13.49
            c0.11,2.54,0.29,5.66,0.6,9.1C54.03,58.52,49.12,60.17,43.98,60.48"
        />
        <path
          style="fill: currentcolor"
          d="M155.71,54.68c-0.46-0.54-1.28-0.6-1.82-0.14c-8.28,7.13-11.26,6.08-11.98,5.59
            c-1.29-0.89-1.92-3.85-0.56-9.55c0.12-0.38,0.25-0.78,0.37-1.2c0.04-0.15,0.06-0.29,0.05-0.44c0.36-1.3,0.81-2.71,1.37-4.26
            c0.24-0.67-0.11-1.41-0.78-1.65c-0.67-0.24-1.41,0.11-1.65,0.78c-0.06,0.16-0.12,0.32-0.17,0.49c-2.77-1.19-5.33-1.21-7.61-0.06
            c-2.29,1.16-3.89,3.35-4.97,5.45c-0.06,0.08-0.11,0.16-0.15,0.25c-0.01,0.03-1.44,3.26-3.44,6.17c-1.33,1.93-3.86,5.05-5.8,4.2
            c-1.55-0.68-0.51-4.78,0.32-8.08c0.32-1.28,0.66-2.6,0.89-3.85c0.34-1.6,0.46-2.89,0.07-3.67c-0.88-1.72-1.79-1.68-4.13-2.22
            l-0.71-0.07l-0.17-0.7c-0.22-0.94-0.35-1.25-0.39-1.34c-0.65-1.51-1.44-1.68-2.06-1.66c-0.54,0.01-0.97,0.21-1.37,0.65
            c-0.42,0.46-0.64,1.01-0.66,1.68c-0.05,0.86,0.18,1.57,0.68,2.19c-0.55,1.86-1.69,5.4-3.24,8.8c-2.68,5.86-5.44,8.82-7.78,8.34
            c-0.6-0.16-1.03-0.55-1.34-1.21c-1.87-3.97,1.13-14.5,4.65-23.98c0.76-0.05,1.69-0.12,1.69-0.15c7.48-0.57,22.16-1.89,25.41-2.28
            c0.52-0.11,0.87-0.38,1.06-0.81c0.19-0.43,0.19-0.84,0.01-1.22c-0.18-0.38-0.54-0.56-1.07-0.52c-10.74,1.29-14.07,1.63-24.88,2.31
            c0,0-0.5,0-1.22,0.01c1.88-4.84,3.78-9.2,5.08-11.97c0.3-0.65,0.02-1.41-0.62-1.72c-0.65-0.3-1.41-0.02-1.72,0.62
            c-0.03,0.07-2.77,5.93-5.55,13.2c-0.71,0.06-1.33,0.15-1.66,0.27c-0.41,0.18-0.68,0.47-0.81,0.87c-0.12,0.4-0.09,0.78,0.09,1.13
            c0.19,0.35,0.53,0.51,1.03,0.5c0.12-0.01,0.22-0.02,0.31-0.02c-0.1,0.29-0.21,0.58-0.31,0.87c-4.39,12.28-5.65,20.13-3.84,23.98
            c0.64,1.36,1.7,2.27,3.05,2.62l0.05,0.01c0.37,0.08,0.74,0.12,1.1,0.12c6.59,0,11.2-13.07,12.81-18.41l1.77,0.33l1.15,0.15
            c1.41,0.11,1.04,1.33,0.9,2.31c-0.15,1.1-0.48,2.84-1.35,6.48h0.01c-0.9,3.89-1.27,7.49,1.71,8.79c0.57,0.25,1.14,0.36,1.71,0.36
            c2.32,0,4.53-1.92,6.35-4.24c0.07,1.76,0.7,3.01,1.89,3.73c0.62,0.37,1.33,0.54,2.1,0.54c2.55,0,5.67-1.85,7.54-4.16
            c0.13-0.14,0.48-0.55,0.96-1.27c0.24,2.16,1.03,3.71,2.36,4.63c0.82,0.56,1.77,0.84,2.86,0.84c3.05,0,7.16-2.21,12.26-6.6
            C156.11,56.04,156.17,55.22,155.71,54.68 M135.22,57.16l-0.08,0.09c-1.97,2.45-5.18,3.76-6.32,3.07
            c-0.97-0.59-1.16-3.42,1.48-9.47c0.03-0.07,0.05-0.14,0.07-0.21c0.87-1.64,2.09-3.27,3.73-4.1c1.63-0.83,3.47-0.76,5.62,0.22
            c-0.34,1.1-0.63,2.14-0.87,3.13C137.13,55.09,135.24,57.14,135.22,57.16"
        />
        <path
          style="fill: currentcolor"
          d="M25.46,95.3h0.94l2.08,4.86h-1.07l-0.45-1.1h-2.09l-0.45,1.1h-1.04L25.46,95.3z M26.6,98.16l-0.68-1.64 l-0.68,1.64H26.6z"
        />
        <polygon
          style="fill: currentcolor"
          points="29.63,95.34 30.65,95.34 30.65,99.24 33.08,99.24 33.08,100.16 29.63,100.16"
        />
        <polygon
          style="fill: currentcolor"
          points="34.28,95.34 35.3,95.34 35.3,99.24 37.73,99.24 37.73,100.16 34.28,100.16"
        />
        <rect
          x="38.69"
          y="97.54"
          style="fill: currentcolor"
          width="2.11"
          height="0.98"
        />
        <rect
          x="42.17"
          y="95.34"
          style="fill: currentcolor"
          width="1.02"
          height="4.83"
        />
        <polygon
          style="fill: currentcolor"
          points="44.81,95.34 45.74,95.34 48.07,98.38 48.07,95.34 49.08,95.34 49.08,100.16 48.21,100.16
            45.81,97.02 45.81,100.16 44.81,100.16"
        />
      </g>
    </g>
  </svg>
</template>
